import React from 'react'
import Bredcrumb from '../Bredcrumb/Careermain'
import Applyform from '../Mencareer/Applyform'


function Main() {
  return (
    <>
      <Bredcrumb title="Join the team" subtitle="Career Services"  />
      <Applyform />
    </>
  )
}

export default Main