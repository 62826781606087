import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Bgtwo from "../../assets/images/03photos/food/1Z6A1114.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import author1 from "../../assets/images/resource/author-thumb-1.jpg";
import author2 from "../../assets/images/resource/author-thumb-2.jpg";
import author3 from "../../assets/images/resource/author-thumb-3.jpg";
import SwiperCore, { Controller } from "swiper";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

SwiperCore.use([Controller]);

function Contact() {
    const swiper1 = useRef(null);
    const swiper2 = useRef(null);
    const [date, setDate] = useState(new Date());
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');

    const [flag, setflag] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());

        // Validation Flags
    let isValid = true;
    let validationMessage = '';

        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(data.date)) {
        validationMessage += 'Please enter a valid date in the format YYYY-MM-DD.\n';
        isValid = false;
    } else {
        const dateObj = new Date(data.date);
        if (isNaN(dateObj.getTime())) {
            validationMessage += 'The date provided is invalid.\n';
            isValid = false;
        }
    }

    // If validation fails, show the validation message
    if (!isValid) {
        setMessage(validationMessage);
        setShowModal(true);
        return;
    }
    
        console.log("Form Data:", data); // Log the form data to check if it's correct
    
        try {
            const response = await fetch('https://no3lounge-9eeef24429d5.herokuapp.com/api/reserve', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                setMessage('Thank you, a member of our staff will reach out to you.');
            } else {
                setMessage('Failed to submit your reservation, please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred while submitting your reservation.');
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const slider2 = useRef();
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false);
            if (slider2.current) {
                slider2.current.to(e.item.index, duration);
            }
            setflag(false);
        }
    };

    // Inline Modal Component
    const Modal = ({ show, message, onClose }) => {
        if (!show) return null;
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <p>{message}</p>
                    <button onClick={onClose} className="close-button">Close</button>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="testimonials-section">
                <div
                    className="image-layer"
                    style={{ backgroundImage: `url(${Bgtwo})` }}
                ></div>
                <div className="auto-container">
                    {/* Testimonials carousel and other content here */}
                </div>
            </div>

            <section className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Online Reservation</h2>
                                        <div className="request-info">
                                            Booking request <Link to="+234 901 000 6669">+234 901 000 6669</Link> or
                                            fill out the order form
                                        </div>
                                    </div>
                                    <div className="default-form reservation-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-user"></span>
                                                        <input
                                                            className="l-icon"
                                                            type="text"
                                                            name="name"
                                                            placeholder="Your Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-phone"></span>
                                                        <input
                                                            className="l-icon"
                                                            type="text"
                                                            name="phoneNumber"
                                                            placeholder="Phone Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input
                                                            className="l-icon"
                                                            type="text"
                                                            name="emailAddress"
                                                            placeholder="Email Address"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-user"></span>
                                                        <select className="l-icon" name="numberOfPeople">
    <option value="1">1 Person</option>
    <option value="2">2 Person</option>
    <option value="3">3 Person</option>
    <option value="4">4 Person</option>
    <option value="5">5 Person</option>
    <option value="6">6 Person</option>
    <option value="7">7 Person</option>
    <option value="8">8 Person</option>
    <option value="9">9 Person</option>
    <option value="10">10 or more Person</option>
</select>
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
    <div className="field-inner">
        <DatePicker
            selected={date}
            onChange={(newDate) => {
                setDate(newDate);
                // Update the hidden input's value
                document.getElementById('reservation-date').value = newDate.toISOString().split('T')[0];
            }}
            dateFormat="yyyy-MM-dd" // Use the format expected by your backend (e.g., "YYYY-MM-DD")
            className="form-control"
        />
        <input
            type="hidden"
            id="reservation-date"
            name="date" // This name should match your backend field
            value={date.toISOString().split('T')[0]} // Initial value setup
        />
    </div>
</div>
                                                <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-clock"></span>
                                                        <select className="l-icon" name="time">
                                                            <option>04 : 00 pm</option>
                                                            <option>04 : 30 pm</option>
                                                            <option>05 : 00 pm</option>
                                                            <option>05 : 30 pm</option>
                                                            <option>06 : 00 pm</option>
                                                            <option>06 : 30 pm</option>
                                                            <option>07 : 00 pm</option>
                                                            <option>07 : 30 pm</option>
                                                            <option>08 : 00 pm</option>
                                                            <option>08 : 30 pm</option>
                                                            <option>09 : 00 pm</option>
                                                            <option>09 : 30 pm</option>
                                                            <option>10 : 00 pm</option>
                                                            <option>10 : 30 pm</option>
                                                            <option>11 : 00 pm</option>
                                                            <option>11 : 30 pm</option>
                                                        </select>
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <textarea
                                                            name="specialRequest"
                                                            placeholder="Give us your details for your planned event, requirements, and any other information required."
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <button
                                                            type="submit"
                                                            className="theme-btn btn-style-one clearfix"
                                                        >
                                                            <span className="btn-wrap">
                                                                <span className="text-one">book a table</span>
                                                                <span className="text-two">book a table</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no">
                                                <Link to="tel:+234 901 000 6669">+234 901 000 6669</Link>
                                            </div>
                                        </div>
                                        <div className="separator">
                                            <span></span>
                                        </div>
                                        <ul className="info">
                                            <li>
                                                <strong>Location</strong>
                                                <br />
                                                #3 University Road, Akoka Lagos
                                            </li>
                                            <li>
                                                <strong>Restaurant Time</strong>
                                                <br />
                                                Monday to Sunday <br />
                                                4.00 pm - 12:00am
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showModal} message={message} onClose={closeModal} />
            </section>
        </>
    );
}

export default Contact;
